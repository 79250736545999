import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Components
import Background from './background'
import Header from './header'

export default ({
    title,
    description,
    bg,
    slug
}) => {
    return (
        <ModalLink
            to={`/${slug}/`} 
            state={{
                modal: true
            }}
        >
            <Header
                title={title}
            />
            <Background 
                bg={bg}
                description={description}
            />
        </ModalLink>
    )
}

// Styled Components
const ModalLink = styled(Link)`
    height:175px;
    width:100%;
    outline:0;
    border:none;
    background-color:transparent;
    -webkit-appearance:none;
    display:flex;
    position:relative;
    border-radius:0.5rem;
    overflow:hidden;
    box-shadow: rgba(37, 44, 97, 0.15) 0px 5px 15px 0px, rgba(93, 100, 148, 0.2) 0px 2px 4px 0px;
    cursor:pointer;
    transition: all .2s;
    top:0;
    &:after {
        content: '';
        display:block;
        background-color:rgba(0,0,0,.55);
        transition: all .2s;
        z-index: 1;
        position: absolute;
        height:100%;
        width: 100%;
    }
    &:hover:after {
        background-color:rgba(0,0,0,.45);
    }
    @media (min-width:992px) {
        height:300px;
        &:hover {
            top:-0.35rem;
            box-shadow:-2px 8px 22px 0 rgba(0,0,0,.30);
        }
    }
`