import { graphql, useStaticQuery } from 'gatsby'

export default () => {
    const { allWordpressWpProject: { 
        nodes 
    }} = useStaticQuery(graphql`
        query useProjects {
            allWordpressWpProject {
                nodes {
                    id
                    slug
                    acf {
                        title
                        description
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fixed(height: 300, width: 372, quality: 80) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }      
    `)

    return nodes
}