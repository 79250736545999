import React from 'react'

// Components
import Header from './header'
import Intro from './intro'

// Hooks
import useProjects from '../../hooks/projects/useProjects'

export default () => {
    return (
        <React.Fragment>
            <Header 
                title={`Our Work`}
                description={`We have decades of experience creating spaces that are aesthetically beautiful as well as functional for families.`}
            />
            <Intro 
                projects={useProjects()}
            />
        </React.Fragment>
    )
}