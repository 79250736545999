import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../../components/container'
import Portfolio from '../../components/portfolio/index'

export default ({
    projects
}) => {
    return (
        <Section id="work-list">
            <Container
                sm={`
                    padding-bottom:3rem;
                `}
                lg={`
                    padding-top:4.5rem;
                    padding-bottom:4.5rem;
                `}
            >
                <List>
                    {projects.map(({
                        id,
                        slug,
                        featured_media,
                        acf: {
                            title,
                            description: excerpt
                        }
                    }) => (
                        <Portfolio
                            bg={featured_media ? featured_media.localFile.childImageSharp.fixed : false}
                            key={id}
                            title={title}
                            description={excerpt}
                            slug={slug}
                        />
                    ))}
                </List>
            </Container>
        </Section>
    )
}

// Styled Components
const Section = styled.section`
    background-color:rgb(245,245,250);
`
const List = styled.div`
    list-style:none;
    margin:0;
    padding:0;
    display:grid;
    grid-template-columns:repeat(2, minmax(0, 1fr));
    grid-column-gap:0.75rem;
    grid-row-gap:0.75rem;
    @media (min-width:992px) {
        grid-column-gap:1.5rem;
        grid-row-gap:1.5rem;
        grid-template-columns:repeat(3, minmax(0, 1fr));
    }
`