import React from 'react'
import styled from 'styled-components'

export default ({
    title,
}) => {
    return (
        <Title>{title}</Title>
    )
}

// Styled Components
const Title = styled.h3`
    position:absolute;
    bottom:0;
    left:0;
    padding:0.75rem;
    z-index:2;
    text-align:left;
    max-width:500px;
    color:#fff;
    line-height:1.25;
    font-size:1rem;
    @media (min-width:992px) {
        padding:1.5rem;
        font-size:1.15rem;
    }
`