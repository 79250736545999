import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-image'

export default ({
    bg,
    description
}) => {
    const { file } = useStaticQuery(graphql`
        query portfolioPlaceholderQuery {
            file(relativeDirectory: {eq: "placeholders"}, name: {eq: "bg"}) {
                childImageSharp {
                    fixed(height: 300, width: 372, quality: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const placeholder = file.childImageSharp.fixed

    return (
        <BackgroundImage style={{ "width": "100%", "height": "auto" }} fixed={bg ? bg : placeholder} alt={description}/>
    )
}
